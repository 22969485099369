import React, { Fragment, Component } from "react";
import { Link } from "gatsby";
import axios from "axios";
import { version } from "../../package.json";
// import sectionIcon from "../images/section-icon.png";


var Api_url = process.env.GATSBY_API_URL;

const axiosConfig = {
  headers: {
    "Content-Type": "application/json; text/html; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
};
class Footer extends Component {
  constructor() {
    super();
    this.state = {
      scrollBtn: false,
      VersionInfo: ""
    };
  }

  getVersionInfo = async () => {
    console.log("getVersionInfo called ");
    var url = Api_url + `/api/pages/version-info`;
    console.log("url=" + url);
    var postData = {
      UserInterface: 3
    };
    console.log({ postData });
    await axios
      .post(url, postData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));

        var records = res.data.records;
        console.log({ records });
        this.setState({ VersionInfo: records, });
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getVersionInfo();
  }

  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({
        scrollBtn: true,
      });
    } else if (window.scrollY < 100) {
      this.setState({
        scrollBtn: false,
      });
    }
  };

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <Fragment>
        <section className="footer-area">
          {/* <div className="newsletter-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mx-auto text-center">
                  <div className="section-heading footer-heading">
                    <div className="section-icon">
                      <img src={sectionIcon} alt="section-icon" />
                    </div>
                    <h2 className="section__title text__white">Newsletter</h2>
                    <p className="section__meta">stay updated</p>
                  </div>
                  <div className="newsletter-form">
                    <div className="form-shared">
                      <form action="#">
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email address"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <button
                              className="theme-btn submit__btn"
                              type="submit"
                            >
                              subscribe
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="footer-top">
            <div className="container">
              <div className="row footer-widget-wrap">
                <div className="col-lg-4 footer-item footer-item1">
                  <h3 className="widget__title">about</h3>
                  <p className="section__desc">
                    The JCI India Foundation is a trust created to provide financial support for JCI India programs and activities.  The funds donated to the foundation, are invested in training young leadership, financial help through scholarship to school children and social welfare projects
                  </p>
                </div>
                <div className="col-lg-2 footer-item footer-item2">
                  <h3 className="widget__title">Menu</h3>
                  <ul className="foot__links">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us/why-jci">About JCI</Link>
                    </li>
                    <li>
                      <Link to="/about-us/jci-india">JCI India</Link>
                    </li>
                    <li>
                      <Link to="/about-us/jci-india-foundation">JCI India Foundation</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/titles/hgf-title">HGF</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfm-title">JFM</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 footer-item footer-item3">
                  <h3 className="widget__title">Quick links</h3>
                  <ul className="foot__links">
                    <li>
                      <Link to="/titles/jfd-title">JFD</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfp-title">JFP</Link>
                    </li>
                    <li>
                      <Link to="/titles/jff-title">JFF</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfs-title">JFS</Link>
                    </li>
                    <li>
                      <Link to="/titles/rpp-title">RPP</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfj-title">JFJ</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/return-and-refund-policy">Return & Refund Policy</Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-3 footer-item footer-item3">
                  <h3 className="widget__title">blog</h3>
                  <ul className="foot__links">
                    <li>
                      <a href="#none">Care for All People</a>
                    </li>
                    <li>
                      <a href="#none">Build school for kids</a>
                    </li>
                    <li>
                      <a href="#none">Being Volunteer</a>
                    </li>
                    <li>
                      <a href="#none">Raise fund to help</a>
                    </li>
                  </ul>
                </div> */}
                <div className="col-lg-4 footer-item footer-item4">
                  <h3 className="widget__title">contact</h3>
                  <ul className="contact__info">
                    <li>JCI India</li>
                    <li>National Head Quarters</li>
                    <li>506 Windfall, Sahar Plaza, </li>
                    <li>J.B. Nagar, Andheri (EAST), </li>
                    <li>Mumbai 400 059</li>
                    <li>
                      <a href="mailto:foundation@jciindia.in">
                        foundation@jciindia.in
                      </a>
                    </li>
                    <li>
                      <a href="tel:022-71117112">
                        (022)-71117112
                      </a>
                    </li>
                  </ul>
                  <div className="footer__social">
                    <ul>
                      <li>
                        <a href="#none">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#none">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#none">
                          <i className="fa fa-youtube-play"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#none">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="copyright-desc">
                    <p>
                      © Copyright {new Date().getFullYear()} by <a href="http://jciindia.in" target="_blank">JCI India</a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="copyright-desc">
                    <p>Version {version}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="copyright-desc">
                    <p>
                      Powered by <a href="http://vaagai.org.in" target="_blank"> Vaagai Tecknowledge</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <br />
                  <p className="text-center">{this.state.VersionInfo}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          role="button"
          onKeyUp={this.scrollTop}
          tabIndex="0"
          onClick={this.scrollTop}
          id="back-to-top"
          className={this.state.scrollBtn ? "back-btn-shown" : ""}
        >
          <i className="fa fa-angle-up" title="Go top"></i>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
